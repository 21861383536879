import React from "react";

const TitleButton = (props) => {

    return (

        <div className={`kuda-section--100 flex justify-center ${typeof props.isInnerSection !== "undefined" ? "kuda-inner--section" : ""}`}>
            <div className="kuda-main--wrap flex flex-column align-center mt-0">
                {typeof props.text !== "undefined" && <p className={`card-main--heading mb-4 text-semi-bold color-black ${typeof props.button !== "undefined" ? "" : "no-margin"}`}>
                    {props.text}
                </p>
                }
                {typeof props.button !== "undefined" && <a href={props.url} className={`kuda-cta kuda-cta-long ${props.isWebButton? " kuda_web":" cta_button"}`} target="_blank" rel="noopener noreferrer" >{props.button}</a>}

            </div>
        </div>


    )
}

export default TitleButton;
