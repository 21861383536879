import React from "react"
import { Component, Fragment } from "react"
import TitleButton from "../../../general/titleButton"
import { Link } from "gatsby"
import { scrollToElement } from "../../../../../utility/utils"
import CTA from "../../../general/cta"


class FAQs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 0,
      panelData: [],
      faqs: null,
      getLocalFaqs: [],
    }
    this.activateTab = this.activateTab.bind(this)
  }

  activateTab(index) {
    this.setState(prev => ({
      activeTab: prev.activeTab === index ? -1 : index,
    }))
  }

  getdata = async firebase => {
    const data = await firebase.FireBase()
    this.setState({
      getLocalFaqs: firebase.getFaqs(),
    })
    return data
  }

  componentDidMount = async () => {
    setTimeout(() => {
      scrollToElement()
      window.addEventListener("scroll", function () {
        scrollToElement()
      })
    }, 0)

    import("../../../../../../firebase").then(firebase => {
      this.getdata(firebase)
    })

    this.setState({
      faqs: localStorage.getItem("kudaFaqs"),
    })
  }

  panels = []
  render() {
    if (this.state.faqs !== null) {
      this.panels = JSON.parse(this.state.faqs)
    } else {
      this.panels = this.state.getLocalFaqs
    }

    return (
      <Fragment>
        <div className="kuda-inner--heading kuda-disclaimer--section">
          <div className="kuda-section--inner general-style">
            <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
              <Link to="/en-ng/legal/" className="color-black mb-3 mt-4 back-page">
                <div className="align-self-center mr-2">
                  <div className="f-14">
                    Legal
                  </div>
                </div>
                <span className="mr-2">
                  <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                      fill="#C7C7CC"
                    ></path>
                  </svg>
                </span>
                <span className="align-self-center f-14 mr-2">Nigeria</span>
                <span className="mr-2">
                  <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                      fill="#C7C7CC"
                    ></path>
                  </svg>
                </span>
                <span className="align-self-center f-14 text-bold text-left">Frequently Asked Questions</span>
              </Link>
              <div className="legal-left">
                <h1 className="kuda-section--heading text-lg-biz mb-0 text-xlbold color-primary title-bottom--spacing no-margins">
                  Frequently Asked Questions - App
                </h1>
              </div>
            </div>
            <div className="kuda-section--100 kuda-spacing--inner">
              <p className="color-primary text-bold bottom--spacing">
                Questions people ask us often:
              </p>
              <p className="color-primary text-bold bottom--spacing">
                Is Kuda a registered bank?
              </p>
              <p className="color-black bottom--spacing">
                Yes, we have a microfinance banking license from the Central Bank of Nigeria. Our registration number is RC 796975.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                How is Kuda different from other banks?
              </p>
              <p className="color-black bottom--spacing">
                We don’t have branches (because you don’t need branches). Kuda runs entirely on your phone (with an internet connection) and we’ll deliver your debit card to any address in Nigeria.
              </p>
              <p className="color-black bottom--spacing">
                Because we don’t have branches, we save a lot of money. That’s why we can afford not to charge you ridiculous fees and pay you more interest than most banks.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                What fees do you charge?
              </p>
              <p className="color-black bottom--spacing">
                We’ll give you 25 free transfers every month if you sign up with your Bank Verification Number (BVN).
              </p>
              <p className="color-black bottom--spacing">
                Extra transfers will cost you 10 naira each.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                How does Kuda make money?
              </p>
              <p className="color-black bottom--spacing">
                We make money by using collective deposits to make investments and issue credit.
              </p>
              <p className="color-black bottom--spacing">
                We also make money from commission on airtime purchases and from service fees sellers pay us when people like you shop with their Kuda Card.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                Do you give loans?
              </p>
              <p className="color-black bottom--spacing">
                We give overdrafts, and you’ll be eligible to take an overdraft if you use your Kuda account regularly.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                What do I need to open a Kuda account?
              </p>
              <p className="color-black bottom--spacing">
                To open a Kuda account, you must be at least 16 years old and you must have an email address and a phone number.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                What kind of account will I get?
              </p>
              <p className="color-black bottom--spacing">
                If you sign up with your name, phone number and BVN (Bank Verification Number), you’ll get an account limited to a maximum balance of 300,000 naira, a maximum deposit of 50,000 naira at a time and a maximum transfer of 50,000 naira at a time.
              </p>
              <p className="color-black bottom--spacing">
                If you add a government-issued ID (like your driver’s license, national ID card or international passport) to your Kuda profile, we’ll remove those limits from your account.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                Why do you need my BVN?
              </p>
              <p className="color-black bottom--spacing">
                We ask you to confirm your BVN so we can be sure no one is pretending to be you.
              </p>
              <p className="color-black bottom--spacing">
                When you confirm your BVN, we match your details with the information on the national BVN database owned by the Central Bank.
              </p>
              <p className="color-black bottom--spacing">
                We won’t use your BVN to access your other bank account(s).
              </p>
              <p className="color-primary text-bold bottom--spacing">
                Is there a minimum balance?
              </p>
              <p className="color-black bottom--spacing">
                No, we don’t have a minimum account balance.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                Is my money safe with Kuda?
              </p>
              <p className="color-black bottom--spacing">
                Yes, your money is safe with us. We secure all accounts with the same high-security encryption used by regular banks.
              </p>
              <p className="color-black bottom--spacing">
                For extra protection, we insure every deposit you make into your Kuda account.
              </p>
              <p className="color-black bottom--spacing">
                If all banks are wiped out by a natural disaster or something, the Deposit Insurance Fund of the National Deposit Insurance Commission (NDIC) is required to pay you 200,000 naira as compensation.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                Since you don’t have branches, how will I get my card?
              </p>
              <p className="color-black bottom--spacing">
                We’ll deliver your Kuda Card to you anywhere in Nigeria.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                How will you add money to my Kuda account?
              </p>
              <p className="color-black bottom--spacing">
                There are several ways to add money to your Kuda account:
              </p>
              <p className="color-black bottom--spacing">
                You can transfer money to your Kuda account from any Nigerian bank with a bank app or through internet banking.
              </p>
              <p className="color-black bottom--spacing">
                You can also add money to your upgraded account with a Nigerian debit card. Tap Add Money on your Kuda dashboard and choose the Add By Card option.
              </p>
              <p className="color-black bottom--spacing">
                If you have cash, you can deposit it into your Kuda account at our partner banks. Tap Add Money on your Kuda dashboard and choose the Add By Deposit option to get instructions.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                Will I get account statements?
              </p>
              <p className="color-black bottom--spacing">
                Yes, you can request an account statement for a specific time period.
              </p>
              <p className="color-black bottom--spacing">
                To request an account statement, tap More on the app then tap Statements & Reports
              </p>
              <p className="color-primary text-bold bottom--spacing">
                Where’s your office?
              </p>
              <p className="color-black bottom--spacing">
                We’re at 1-11 Commercial Avenue, Yaba, Lagos.
              </p>
              <p className="color-primary text-bold bottom--spacing">
                What should I do if I need help?
              </p>
              <p className="color-black bottom--spacing">
                The easiest way to reach us is to tap More on the Kuda app then tap Chat With Us.
              </p>
              <p className="color-black bottom--spacing">
                You can also send an email to   <a
                  href="mailto:help@kuda.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="color-primary text-bold link-decoration cursor-pointer"
                >
                  help@kuda.com
                </a> or call 01 633 5832 between 8:00 am and 5:00 pm on weekdays.
              </p>

              {/* <div className="accordion" role="tablist">
                {this.panels.map((panel, index) => (
                  <Panel
                    key={index}
                    activeTab={activeTab}
                    index={index}
                    {...panel}
                    activateTab={this.activateTab.bind(null, index)}
                  />
                ))}
              </div> */}
              <TitleButton
                text="Don't see your question?"
                button="Visit our Help Center"
                url="https://help.kuda.com/support/solutions/articles/73000559888-faqs"
                isInnerSection={true}
              />
            </div>
          </div>
        </div>
        <CTA />
      </Fragment>
    )
  }
}
export default FAQs
